<template>
  <Transition>
    <Teleport to="body">
      <div
        v-if="open"
        class="fixed top-0 z-50 flex h-dvh w-screen flex-col bg-black"
      >
        <div class="container flex items-center justify-between p-6">
          <NuxtLink to="/">
            <img
              src="@/assets/images/logo.svg"
              alt="Foodyard"
            >
          </NuxtLink>
          <button
            type="button"
            class="flex items-center justify-center rounded-full bg-secondary p-4"
            @click="open = !open"
          >
            <Icon
              name="mdi:close"
              class="size-8"
            />
          </button>
        </div>
        <ul class="flex grow flex-col items-center justify-center gap-16">
          <li
            v-for="item in menu"
            :key="item.label"
          >
            <UiLink
              class="text-xl"
              variation="menu"
              active-class="bg-secondary  text-black"
              :to="item.url"
              @click="open = false"
            >
              {{ item.label }}
            </UiLink>
          </li>
        </ul>
        <div class="container flex flex-col items-center gap-10 p-6">
          <FormKit
            v-model="search.query.value"
            placeholder="Events zoeken..."
            type="search"
            outer-class="$reset w-full"
            input-class="w-full"
            @keydown.enter="navigateTo({name: 'zoeken'}); open = false"
          />
          <UiLink
            :to="{name: 'account'}"
            variation="outlineWhite"
            class="w-full text-center"
            @click="open = false"
          >
            Mijn Account
          </UiLink>
        </div>
      </div>
    </Teleport>
  </Transition>
</template>

<script setup lang="ts">
import {useScrollLock} from '@vueuse/core';

const open = defineModel('open', {type: Boolean});
const {menu} = useSofieMenu('Navigation');
const search = useSearch();

const isLocked = useScrollLock(document.body);
isLocked.value = open.value;
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
