<template>
  <div class="mt-12 lg:mt-24">
    <NuxtMarquee
      :auto-fill="true"
      class="mb-4 flex items-center justify-evenly text-white sm:mb-8"
    >
      <NuxtPicture
        v-for="image in footerImages"
        :key="image.id"
        :alt="image.title ?? ''"
        :img-attrs="{class: 'md:ml-8 ml-4 rounded-2xl object-cover size-16 md:size-32 xl:size-64'}"
        :src="image.url"
      />
    </NuxtMarquee>

    <footer class="bg-primary p-6 md:p-12 ">
      <div class="rounded-3xl bg-black p-6 md:p-12">
        <div class="flex flex-col items-center gap-8 md:mb-16">
          <h2 class="text-4xl text-white md:text-6xl">
            Ga ook op avontuur en blijf up to date!
          </h2>
          <div class="flex flex-col items-center gap-8 md:flex-row">
            <UiLink
              :to="{name: 'index', hash: '#aanmelden-nieuwsbrief'}"
              variation="primary"
            >
              Aanmelden nieuwsbrief
            </UiLink>

            <UiLink
              :to="{name:'login'}"
              variation="outlineWhite"
            >
              Account aanmaken
            </UiLink>
          </div>
        </div>
        <div class="flex flex-col items-center justify-between gap-6 rounded-2xl p-6 md:bg-neutral-800 lg:flex-row">
          <button
            type="button"
            aria-label="Back to top"
            class="hidden w-fit rounded-xl bg-black p-4 text-white md:flex"
            @click="y = 0"
          >
            <Icon
              name="mdi:arrow-up"
              class="size-6"
            />
          </button>
          <NuxtLink
            class="mx-auto"
            target="_blank"
            to="https://bidfood.nl"
          >
            <img
              class="h-8"
              src="/bidfood_white.svg"
              alt="Bidfood"
            >
          </NuxtLink>
          <ol class="flex flex-col items-center gap-8 md:flex-row md:gap-16">
            <li
              v-for="item in menu"
              :key="item.label"
            >
              <NuxtLink
                :to="item.url"
                class="text-white"
              >
                {{ item.label }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="{name: 'account'}"
                class="text-white"
              >
                Mijn account
              </NuxtLink>
            </li>
          </ol>
          <div class="flex-row rounded-2xl bg-neutral-800 p-6 md:ml-auto">
            <ul class="flex gap-4">
              <li
                v-for="item in socials"
                :key="item.label"
              >
                <a
                  :href="item.url"
                  target="_blank"
                  nofollow
                >
                  <Icon
                    :name="item.icon"
                    class="size-6 text-white"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import {useFooterQuery} from '~/graphql/graphql';

const {menu} = useSofieMenu('Navigation');
const {result} = useSofieSettings();
const {result: footerResults} = useFooterQuery();
const footerImages = computed(() => footerResults.value?.footer ?? []);

const icons: Record<string, string> = {
  twitter: 'fa6-brands:x-twitter',
  facebook: 'fa6-brands:facebook-f',
  instagram: 'fa6-brands:instagram',
  youtube: 'fa6-brands:youtube',
};

const displaySocials = ['instagram', 'facebook'];

const socials = computed(() => {
  const list: Array<Record<string, string>> = [];

  if (!result.value?.settings) {
    return list;
  }

  for (const item of result.value.settings) {
    if (item?.value != '' && typeof item?.value === 'string' && Object.keys(icons).includes(item.key) && displaySocials.includes(item.key)) {
      list.push({label: item.key, url: item?.value, icon: icons[item.key]});
    }
  }

  return list;
});

const {y} = useWindowScroll({behavior: 'smooth'});
</script>
