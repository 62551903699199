<template>
  <nav class="sticky top-0 z-50 bg-black p-6 lg:py-10">
    <div class="container flex items-center justify-between">
      <NuxtLink to="/">
        <img
          src="@/assets/images/logo.svg"
          alt="Foodyard"
        >
      </NuxtLink>
      <ul class="hidden gap-6 lg:flex">
        <li
          v-for="item in menu"
          :key="item.label"
        >
          <UiLink
            variation="menu"
            active-class="bg-secondary !text-black"
            :to="item.url"
          >
            {{ item.label }}
          </UiLink>
        </li>
      </ul>
      <div class="hidden items-center gap-6 lg:flex">
        <FormKit
          v-model="search.query.value"
          placeholder="Events zoeken..."
          type="search"
          outer-class="$reset"
          @keydown.enter="navigateTo({name: 'zoeken'})"
        />
        <UiLink
          :to="{name: 'account'}"
          variation="outlineWhite"
        >
          Mijn Account
        </UiLink>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          aria-label="Menu"
          class="flex items-center justify-center rounded-full bg-secondary p-4"
          @click="isOpen = !isOpen"
        >
          <Icon
            name="mdi:menu"
            class="size-8"
          />
        </button>
        <AppMobileMenu
          v-if="isOpen"
          v-model:open="isOpen"
        />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const {menu} = useSofieMenu('Navigation');
const search = useSearch();
const isOpen = ref(false);
</script>
